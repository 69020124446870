*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  background: #0D0D0D;
  color: #fff;
}

h1,
h2,
h3 {
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: .5rem;
}

h1 {
  font-size: 5.063rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: #04BF9D;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 1rem;
}

a:hover {
  text-decoration: underline;
}

.display {
  font-weight: 700;
  color: #04BF9D;
}

.container {
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
}

input {
  font: inherit;
  padding: 1rem;
  display: block;
  width: 100%;
  margin-bottom: 1rem;;
  border: 1px solid #aaa;
  background: transparent;
  transition: border-color 300ms ease;
  outline: 0;
  color: #fff;
}

input:hover {
  border-color: #fff;
}

input:focus {
  border-color: #04BF9D;
}

button {
  cursor: pointer;
  font: inherit;
}

.button {
  background: #04BF9D;
  color: #000;
  padding: .5rem 1rem;
  transition: all 300ms ease;
  display: inline;
  border: 1px solid #04BF9D;
  margin: .5rem;
}

.button--block {
  width: 100%;
  display: block;
  margin: 0;
}

.button:hover {
  background: #03A688;
  color: #000;
}

.loading {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing {
  text-align: center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  max-width: 25rem;
  margin: 0 auto;
}